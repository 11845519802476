import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Grid,TextField, Typography } from "@mui/material";
import StyledButton from "../../components/Button/StyledButton";
import {
  getEmailOtp,
  postOtpForEmail,
  getEmpDetails,
} from "../../services/actionSlice";
import { AppDispatch } from "../../storage/store";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import OtpInput from "react-otp-input";
interface Props {
  setLandingPage: any;
  setEmpId: any;
  empId: any;
  setCustomerEmail: any;
  customerEmail: any;
  setOtpEmail: any;
  otpEmail: any;
  setOtpEmailVerified: any;
}

const LandingPage: React.FC<Props> = ({
  setLandingPage,
  setEmpId,
  empId,
  setCustomerEmail,
  customerEmail,
  setOtpEmail,
  otpEmail,
  setOtpEmailVerified,
}) => {
  const dispatch = useDispatch<AppDispatch>();
  const centreData = useSelector((state: any) => state.webAppSlice.centreByKey);
  const emailSent = useSelector((state: any) => state.webAppSlice.emailOtp);
  const verifiedOtpEmail = useSelector(
    (state: any) => state.webAppSlice.emailOtpVerified
  );

  const [seconds, setSecond] = useState<any>("");
  const verifyEmail = () => {
    dispatch(getEmailOtp(`?email=${customerEmail}&emp_id=${empId}`));
  };

  useEffect(() => {
    if (String(otpEmail).length === 6) {
      dispatch(postOtpForEmail(`?otp=${otpEmail}&email=${customerEmail}`));
    }
  }, [otpEmail]);
  useEffect(() => {
    if (verifiedOtpEmail.token) {
      setOtpEmailVerified(true);
      setLandingPage(false);
      dispatch(getEmpDetails(verifiedOtpEmail.token));
    }
  }, [verifiedOtpEmail]);
  useEffect(() => {
    let timeout: any;
    if (seconds > 0) {
      timeout = setTimeout(
        () => setSecond((previousCount: number) => previousCount - 1),
        1000
      );
    } else {
      setSecond(0);
    }
    return () => clearTimeout(timeout);
  });
  console.log(seconds,"sec")
  return (
    <Grid className="content" id="collectionSlot">
      <Grid container spacing={2} className="dialogContent">
        <Grid item xs={12} textAlign="center">
        <img
            src={centreData?.logo_image}
            alt="LOGO"
            style={{ padding: "5px 0px 0px", margin: "70px auto",width:"250px",height:"auto" }}
          />
        </Grid>
        <Grid item xs={12} textAlign="center">
          <TextField
            className="input"
            variant="outlined"
            label="Employee ID"
            placeholder="Enter your employee ID"
            required
            type="text"
            fullWidth
            value={empId}
            onChange={(e) => setEmpId(e.target.value)}
          />
        </Grid>
        <Grid item xs={12} textAlign="center">
          <TextField
            className="input"
            variant="outlined"
            label="Email Address"
            placeholder="Enter your email address"
            required
            type="email"
            fullWidth
            value={customerEmail}
            onChange={(e) => setCustomerEmail(e.target.value)}
          />
        </Grid>
        <Grid item xs={12} textAlign="left">
          {emailSent?.message === "Otp sent!" ? (
            <>
              <Grid item xs={12}>
                <Typography component="p" color="green">
                  OTP has been shared on your Email.{" "}
                </Typography>
                <Typography component="p">Enter OTP</Typography>
              </Grid>
              <Grid item xs={12}>
                <OtpInput
                  value={otpEmail}
                  isInputNum={true}
                  numInputs={6}
                  onChange={(e: any) => setOtpEmail(e)}
                  inputStyle={{
                    width: "2rem",
                    height: "2rem",
                    margin: "0 0.4rem",
                    fontSize: "14px",
                    borderRadius: 4,
                    border: "1px solid rgba(0,0,0,0.3)",
                    background: "#e7e7e7",
                    color: "#000",
                  }}
                />
              </Grid>
              {verifiedOtpEmail?.errors ? (
                <Grid item xs={12}>
                  <Typography component="p" color="red">
                    <CancelOutlinedIcon
                      style={{
                        color: "red",
                        fontSize: 18,
                        marginBottom: "-3px",
                      }}
                    />{" "}
                    Incorrect OTP!
                  </Typography>
                </Grid>
              ) : (
                ""
              )}
              <Grid item xs={12}>
                {seconds !== 0 ? (
                  <p>
                    Resend OTP in
                    <span style={{ color: "#5866E0" }}>
                      {" "}
                      00:{seconds < 10 ? "0" + seconds : seconds}
                    </span>
                  </p>
                ) : (
                  <p>
                    <span
                      style={{ color: "#5866E0", cursor: "pointer" }}
                      onClick={() => {
                        verifyEmail();
                        setSecond(30);
                      }}
                    >
                      Resend OTP
                    </span>
                  </p>
                )}
              </Grid>
            </>
          ) : (
            <StyledButton
              disabled={!customerEmail || !empId}
              onClick={() => {verifyEmail(); setSecond(30);}}
            >
              Verify Email
            </StyledButton>
          )}
        </Grid>
      </Grid>
    </Grid>
  );
};

export default LandingPage;
