import React, { useEffect, useRef, useState } from "react";
import Header from "../../components/Header";
import "./index.sass";
import StyledButton from "../../components/Button/StyledButton";
import StyledButtonLight from "../../components/Button/StyledButtonLight";
import OtpInput from "react-otp-input";
import GpsFixedIcon from "@mui/icons-material/GpsFixed";
import CloseIcon from "@mui/icons-material/Close";
import infoImg from "../../images/info.svg";
import {
  postMobileForOtp,
  getCentreByKey,
  postVerifyOtp,
  checkGeoFenchMapMyIndia,
  getMapMyIndiaAccessToken,
  getPartnerKey,
  getLocationrRevGeoCode,
  getValidLocation,
  getZone,
  createLead,
  uploadPrescription,
  removeEmpToken,
} from "../../services/actionSlice";
import FileUploadOutlinedIcon from "@mui/icons-material/FileUploadOutlined";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import { useDispatch, useSelector } from "react-redux";
import SearchTwoToneIcon from "@mui/icons-material/SearchTwoTone";
import CollectionSlotPage from "../CollectionSlot";
import ConfirmationPage from "../Confirmation";
import moment from "moment";
import PlaceOutlinedIcon from "@mui/icons-material/PlaceOutlined";
import {
  Box,
  Card,
  InputAdornment,
  Typography,
  TextField,
  Grid,
  Dialog,
  Slide,
  MenuItem,
  Button,
  CircularProgress,
  Autocomplete,
} from "@mui/material";
import { AppDispatch } from "../../storage/store";
import { TransitionProps } from "@mui/material/transitions";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import LandingPage from "../LandingPage/index";
import Loader from "../../components/Loader";
import SnackBar from "../../components/SnackBar/snackBarPopPup";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
const theme = createTheme({
  palette: {
    primary: {
      // Purple and green play nicely together.
      main: "#E06358",
    },
    secondary: {
      // Purple and green play nicely together.
      main: "#2677F0",
    },
  },
});
const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function Main() {
  // const classes = useStyles();
  const dispatch = useDispatch<AppDispatch>();
  const timer = useRef<any>(0);

  const [otpOpen, setOtpOpen] = React.useState(false);

  const [cancel, setCancel] = useState<boolean>(false);
  const [otp, setOtp] = React.useState("");
  const [locationOpen, setLocationOpen] = React.useState(false);
  const [latitude, setLatitude] = React.useState<any>("");
  const [longitude, setLongitude] = React.useState<any>("");
  const [addrLoading, setAddrLoading] = useState<boolean>(false);
  const [openSlotModal, setOpenSlotModal] = useState<boolean>(false);
  const [openConfirmedModal, setOpenConfirmedModal] = useState<boolean>(false);
  const [refData, setRefData] = useState<any>("");
  const [areaServicable, setAreaServicable] = useState<any>(true);
  const [customerName, setCustomerName] = useState<any>("");
  const [fullName, setFullName] = useState<any>("");
  const [customerNumber, setCustomerNumber] = useState<any>("");
  const [customerEmail, setCustomerEmail] = useState<any>("");
  const [customerAge, setCustomerAge] = useState<any>("");
  const [customerGender, setCustomerGender] = useState<any>("");
  const [customerPackages, setCustomerPackages] = useState<any>([]);
  const [customerAddress, setCustomerAddress] = useState<any>("");
  const [addressInput, setAddressInput] = useState<any>("");
  const [customerCity, setCustomerCity] = useState<any>("");
  const [customerPackagesAddn, setCustomerPackagesAddn] = useState<string>("");
  const [collectionDate, setCollectionDate] = useState<any>(
    moment().add(3, "days").format("YYYY-MM-DD")
  );
  const [suggestedAddress, setSuggestedAddres] = useState<any>([]);
  const [collectionSlot, setCollectionSlot] = useState<any>("");
  const [mobileValidation, setMobileValidation] = useState<any>(true);
  const [nameValidation, setNameValidation] = useState<any>(true);
  const [expand, setExpand] = useState<any>(false);
  const [ageValidation, setAgeValidation] = useState<any>(true);
  const [genderValidation, setGenderValidation] = useState<any>(true);
  const [centreName, setCentreName] = useState<string>("");
  const [clientType, setClientType] = useState<any>("");
  const [customerZone, setCustomerZone] = useState<any>(0);
  const [seconds, setSecond] = useState<any>(30);
  const [pincode, setPincode] = useState<any>("");
  const [preview, setPreview] = useState<any>("");
  const [empId, setEmpId] = useState<string>("");
  const [empPackages, setEmpPackages] = useState<any>([]);
  const [otpEmail, setOtpEmail] = useState<string>("");
  const [otpEmailVerified, setOtpEmailVerified] = useState<boolean>(false);
  const [file, setFile] = useState<any>("");
  const [emailValidation, setEmailValidation] = useState<any>(true);
  const [landingPage, setLandingPage] = useState<boolean>(false);
  //link queries

  useEffect(() => {
    const search = window.location.search;
    const params = new URLSearchParams(search);
    const partner_id = params.get("pid");

    if (partner_id) {
      if(partner_id==="3p_KwgwEqzyEhWYgJ7GkS"){
        window.open("https://iframepartner.redcliffelabs.com/?pid=3p_B8s8rb74Ar9R9Ma276","_self");
      }
      setRefData(partner_id);
    }
  }, [window.location.search]);
  useEffect(() => {
    if (refData) dispatch(getCentreByKey(refData));
  }, [refData]);
  const [accessToken, setAccessToken] = useState<string>("");
  //data
  const centreData = useSelector((state: any) => state.webAppSlice.centreByKey);
  const verifiedOtp = useSelector((state: any) => state.webAppSlice.verifyOtp);
  const prevBookingData = useSelector(
    (state: any) => state.webAppSlice.prevBookingData
  );
  const partnerKey = useSelector((state: any) => state.webAppSlice.partnerKey);
  const loading = useSelector((state: any) => state.webAppSlice.loading);
  const validLocation = useSelector(
    (state: any) => state.webAppSlice.validLocation
  );
  const geoFencesMapMyIndia = useSelector(
    (state: any) => state.webAppSlice.geoFencesMapMyIndia
  );
  const mapmyindiaAccessToken = useSelector(
    (state: any) => state.webAppSlice.mapmyindiaAccessToken
  );
  const snackData = useSelector((state: any) => state.webAppSlice.snackbarData);
  const zone = useSelector((state: any) => state.webAppSlice.zone);
  const uploadPrescriptionData = useSelector(
    (state: any) => state.webAppSlice.uploadPrescriptionData
  );

  const leadCreated = useSelector(
    (state: any) => state.webAppSlice.leadCreated
  );
  const employeeDetails = useSelector(
    (state: any) => state.webAppSlice.empDetails
  );
  const verifiedOtpEmail = useSelector(
    (state: any) => state.webAppSlice.emailOtpVerified
  );
  useEffect(() => {
    if (
      file !== "" &&
      Object.keys(uploadPrescriptionData).length !== 0 &&
      Object.keys(leadCreated).length !== 0
    ) {
      setOpenConfirmedModal(true);
    } else if (Object.keys(leadCreated).length !== 0 && file === "") {
      setOpenConfirmedModal(true);
    }
  }, [leadCreated, uploadPrescriptionData]);
  useEffect(() => {
    if (file !== "" && Object.keys(leadCreated).length !== 0) {
      submitPrescription(leadCreated?.id);
    }
    if (verifiedOtpEmail.token && Object.keys(leadCreated).length !== 0) {
      dispatch(removeEmpToken(verifiedOtpEmail.token));
    }
  }, [leadCreated]);

  const revGeoLoc = useSelector((state: any) => state.webAppSlice.revGeoCode);

  useEffect(() => {
    if (
      revGeoLoc?.results &&
      revGeoLoc?.results.length > 0 &&
      validLocation?.valid === true
    ) {
      setAddressInput(revGeoLoc?.results[0].formatted_address);
      setPincode(revGeoLoc?.results[0].pincode);
      setCustomerCity(revGeoLoc?.results[0].city || "");
      setAreaServicable(true);
      setAddrLoading(false);
      handleCloseLocation();
    } else if (validLocation?.valid === false) {
      setAreaServicable(false);
      setAddrLoading(false);
    }
  }, [revGeoLoc, validLocation]);
  const getLatLng = () => {
    setAddrLoading(true);
    navigator.geolocation.getCurrentPosition((position) => {
      setCustomerAddress("");
      setLatitude(position.coords.latitude);
      setLongitude(position.coords.longitude);

      dispatch(
        getValidLocation({
          url: `?longitude=${position.coords.longitude}&latitude=${position.coords.latitude}`,
          key: partnerKey?.center_key,
        })
      );
      dispatch(
        getZone({
          url: `?longitude=${position.coords.longitude}&latitude=${position.coords.latitude}`,
          key: partnerKey?.center_key,
        })
      );
    });
  };
  useEffect(() => {
    if (centreData?.center) {
      dispatch(getPartnerKey(centreData?.center));
      setClientType(centreData?.otp_mode);
      setEmpPackages(centreData?.package_details);
    }
  }, [centreData]);

  useEffect(() => {
    if (centreData.has_partner_user_detail && !otpEmailVerified && clientType) {
      setLandingPage(true);
    } else {
      if (clientType === "PA") {
        setOtpOpen(true);
      } else if (clientType === "AA") {
        setLocationOpen(true);
      } else if (clientType === "NV") {
        setLocationOpen(true);
        setExpand(true);
      }
    }
  }, [clientType, centreData, otpEmailVerified]);
  useEffect(() => {
    if (locationOpen) {
      dispatch(getMapMyIndiaAccessToken());
    }
  }, [locationOpen]);

  const PostOtp = (data: any) => {
    dispatch(
      postMobileForOtp({
        phone_number: data,
      })
    );
  };
  useEffect(() => {
    let timeout: any;
    if (seconds > 0) {
      timeout = setTimeout(
        () => setSecond((previousCount: number) => previousCount - 1),
        1000
      );
    } else {
      setSecond(0);
    }
    return () => clearTimeout(timeout);
  });
  useEffect(() => {
    if (String(customerNumber).length === 10 && otpOpen) {
      PostOtp(customerNumber);
      setSecond(30);
    }
    verifyMobile();
  }, [customerNumber]);
  useEffect(() => {
    if (String(otp).length === 6) {
      dispatch(
        postVerifyOtp({
          phone_number: customerNumber,
          otp: otp,
        })
      );
    }
  }, [otp]);

  useEffect(() => {
    if (geoFencesMapMyIndia) {
      setCustomerZone(geoFencesMapMyIndia?.customer_zone);
    }
  }, [geoFencesMapMyIndia]);

  const handleCloseLocation = () => {
    setLocationOpen(false);
  };

  const verifyEmail = () => {
    const nameRegex =
      /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
    if (customerEmail && !nameRegex.test(customerEmail.toString())) {
      setEmailValidation(false);
    }
  };

  //address
  useEffect(() => {
    if (mapmyindiaAccessToken?.accesstoken !== undefined) {
      setAccessToken(mapmyindiaAccessToken?.accesstoken);
    }
  }, [mapmyindiaAccessToken]);
  useEffect(() => {
    if (zone.results && zone.results.length > 0) {
      setCustomerZone(zone?.results[0].id);
    }
  }, [zone]);
  useEffect(() => {
    if (geoFencesMapMyIndia?.latitude && geoFencesMapMyIndia?.longitude) {
      setLatitude(geoFencesMapMyIndia?.latitude);
      setLongitude(geoFencesMapMyIndia?.longitude);
      setCustomerZone(geoFencesMapMyIndia?.customer_zone);
      setAreaServicable(true);
      handleCloseLocation();
    } else if (geoFencesMapMyIndia?.status === false) {
      setLatitude("");
      setLongitude("");
      setAreaServicable(false);
    }
  }, [geoFencesMapMyIndia]);
  const handlePreview = () => {
    if (file?.type === "application/pdf") {
      let link = URL.createObjectURL(file);
      window.open(link, "_blank");
    } else {
      setPreview(URL.createObjectURL(file));
    }
  };
  useEffect(() => {
    if (customerAddress && customerAddress.placeAddress) {
      setAddressInput(
        customerAddress.placeName + ", " + customerAddress.placeAddress
      );
    }
    if (customerAddress?.eLoc) {
      dispatch(checkGeoFenchMapMyIndia({ eloc: customerAddress?.eLoc }));
    }
  }, [customerAddress]);
  const locationAutoCompleteSearch = (val: string, e: any) => {
    if (val.length > 2) {
      const urlForMap = `https://atlas.mapmyindia.com/api/places/search/json?query=${val}&access_token=${accessToken}&tokenizeAddress=true`;
      return new Promise(function (resolve, reject) {
        const id = "_" + Math.round(10000 * Math.random());
        const callbackName = "jsonp_callback_" + "data" + id;
        //@ts-ignore
        window[callbackName] = (data: any) => {
          //@ts-ignore
          delete window[callbackName];
          const ele = document.getElementById(id);
          ele?.parentNode?.removeChild(ele);
          const suggestedData = data?.suggestedLocations;
          if (suggestedData.length > 0) {
            setSuggestedAddres(data?.suggestedLocations);
          }
        };
        const src = urlForMap + "&callback=" + callbackName;
        const script = document.createElement("script");
        script.src = src;
        script.id = id;
        script.addEventListener("error", reject);
        (
          document.getElementsByTagName("head")[0] ||
          document.body ||
          document.documentElement
        ).appendChild(script);
      });
    }
  };
  const selectAddress = (option: any) => {
    setCustomerCity(option?.addressTokens?.city || "");
    setCustomerAddress(option);
  };

  const verifyName = () => {
    const nameRegex = /^([a-zA-Z ]){2,30}$/;
    if (customerName && !nameRegex.test(customerName.toString())) {
      setNameValidation(false);
    }
  };

  const verifyAge = () => {
    if (customerAge < 5) {
      setAgeValidation(false);
    }
  };
  const blockInvalidChar = (e: React.KeyboardEvent<HTMLDivElement>) =>
    ["e", "E"].includes(e.key) && e.preventDefault();
  useEffect(() => {
    if (latitude && accessToken && longitude && customerAddress === "") {
      dispatch(
        getLocationrRevGeoCode({
          token: accessToken,
          lng: longitude,
          lat: latitude,
        })
      );
    }
  }, [accessToken, latitude]);
  const verifyMobile = () => {
    const mobileRegEx = /^[6-9]/;

    if (customerNumber && !mobileRegEx.test(customerNumber.toString())) {
      setMobileValidation(false);
    } else {
      setMobileValidation(true);
    }
  };

  const handleClose = () => {
    setOtpOpen(false);
    setLocationOpen(true);
  };
  useEffect(() => {
    if (verifiedOtp?.verified === true) {
      handleClose();
    }
  }, [verifiedOtp]);
  const submitLead = async () => {
    const pack =
      (customerPackages.length > 0 &&
        customerPackages.map((val: any) => val.id)) ||
      [];

    const data: any = {
      mobile: customerNumber,
      patient_name: customerName,
      gender: customerGender,
      patient_age: customerAge,
      iframe_event: "RAD",
      city_name: addressInput,
      center: centreData?.center,
      test_package: customerPackagesAddn,
      center_text: centreName,
      collection_date: collectionDate,
      collection_slot: collectionSlot.id,
      email: customerEmail,
      packages: pack,
      lead_type: "b2b",
      org_type: "imaging",
    };
    if (!customerPackagesAddn) {
      delete data.test_package;
    }
    if (!customerEmail) {
      delete data.email;
    }
    if (!centreName) {
      delete data.center_text;
    }
    await dispatch(createLead({ data: data, key: partnerKey?.center_key }));
  };
  const submitPrescription = async (id: any) => {
    const formData = new FormData();
    formData.append("name", customerName);
    formData.append("lead", id);
    formData.append("age", customerAge);
    formData.append(
      "gender",
      customerGender === "male" ? "M" : customerGender === "female" ? "F" : "O"
    );
    formData.append("prescription", file);
    await dispatch(
      uploadPrescription({ data: formData, key: partnerKey?.center_key })
    );
  };
  useEffect(() => {
    const data: any = employeeDetails.data;
    const pack: any = employeeDetails.package_details || [];
    if (data) {
      setCustomerName(data.customer_name || "");
      setCustomerAge(data.customer_age || "");
      setCustomerGender(data.customer_gender || "");
      setFullName(data.customer_name || "");
      setCustomerNumber(data.phonenumber);
    }
    if (pack.length > 0) {
      setEmpPackages(pack);
      // if(pack.length===1){
      //   setCustomerPackages(pack)
      // }
    }
  }, [employeeDetails]);

  return (
    <ThemeProvider theme={theme}>
      <div className="containerMain">
        {!clientType && loading ? (
          <Loader />
        ) : (
          <>
            <Header
              isFilter={false}
              addressInput={addressInput}
              setLocationOpen={setLocationOpen}
            />
            <Box sx={{ flexGrow: 1 }}>
              {prevBookingData && prevBookingData.length > 0 && (
                <div
                  style={{
                    display: "flex",
                    background: "rgba(0, 150, 136, 0.1)",
                    borderRadius: "5px",
                    margin: 15,
                    padding: 15,
                  }}
                >
                  <div style={{ width: "10%" }}>
                    <div
                      style={{
                        background: "#FFF",
                        borderRadius: "50%",
                        textAlign: "center",
                        width: "25px",
                        height: "25px",
                        padding: "2px",
                      }}
                    >
                      <img
                        style={{ width: "6px", marginBottom: "-3px" }}
                        src={infoImg}
                      />
                    </div>
                  </div>
                  <div style={{ width: "90%" }}>
                    Track previous bookings.{" "}
                    <a
                      onClick={() =>
                        window.open(
                          window.location.href + `&tid=${prevBookingData[0].pk}`
                        )
                      }
                      style={{ cursor: "pointer", textDecoration: "underline" }}
                    >
                      Click Here
                    </a>
                  </div>
                </div>
              )}

              <Card sx={{ borderRadius: "16px", m: 1 }}>
                <Grid
                  container
                  spacing={2}
                  className={expand ? "dialogContent" : "dialogContentMain"}
                >
                  <Grid item xs={10}>
                    <Typography component="p">YOUR DETAILS</Typography>
                  </Grid>
                  <Grid item xs={2} style={{ textAlign: "right" }}>
                    {expand ? (
                      <ExpandLessIcon
                        style={{ cursor: "pointer" }}
                        onClick={() => setExpand(false)}
                      />
                    ) : (
                      <ExpandMoreIcon
                        style={{ cursor: "pointer" }}
                        onClick={() => setExpand(true)}
                      />
                    )}
                  </Grid>
                  {expand ? (
                    <>
                      <Grid item xs={12}>
                        <TextField
                          fullWidth
                          className="input"
                          id="outlined-required"
                          label="Full Name"
                          color="primary"
                          value={fullName}
                          onChange={(e) =>
                            String(e.target.value).length <= 30
                              ? setFullName(
                                  e.target.value
                                    .replace(/[^a-zA-Z ]/g, "")
                                    .trimStart()
                                )
                              : ""
                          }
                          required
                          disabled={
                            clientType !== "NV" ||
                            employeeDetails?.data?.customer_name
                          }
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <TextField
                          key={String(customerNumber)?.length === 10 ? "0" : "1"}
                          type="number"
                          label="Mobile Number"
                          value={customerNumber}
                          variant="outlined"
                          onWheel={(e: any) => e.target.blur()}
                          onInput={(e: any) => {
                            e.target.value = Math.max(
                              0,
                              parseInt(e.target.value)
                            )
                              .toString()
                              .slice(0, 10);
                          }}
                          disabled={
                            fullName === "" ||
                            clientType !== "NV" ||
                            employeeDetails?.data?.phonenumber
                          }
                          fullWidth
                          required
                          onChange={(e) => setCustomerNumber(e.target.value)}
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="start">
                                +91
                              </InputAdornment>
                            ),
                          }}
                        />
                        <sub style={{ color: "red" }}>
                          {!mobileValidation
                            ? "Please enter valid mobile number"
                            : ""}
                        </sub>
                      </Grid>
                    </>
                  ) : (
                    ""
                  )}
                </Grid>
              </Card>
              <Card sx={{ borderRadius: "16px", m: 1 }}>
                <Grid container spacing={2} className="dialogContent">
                  <Grid item xs={12}>
                    <Typography component="p">PATIENT INFORMATION</Typography>
                  </Grid>

                  <>
                    <Grid item xs={9}>
                      Patient 1
                    </Grid>
                    <Grid item xs={3} textAlign="right"></Grid>
                    <Grid item xs={12}>
                      <TextField
                        fullWidth
                        color="primary"
                        className="input"
                        id="outlined-required"
                        label="Name"
                        value={customerName}
                        disabled={employeeDetails?.data?.customer_name}
                        required
                        onBlur={() => verifyName()}
                        onFocus={() => {
                          setNameValidation(true);
                        }}
                        onChange={(e) =>
                          setCustomerName(String(e.target.value).trimStart())
                        }
                      />
                      <sub style={{ color: "red" }}>
                        {!nameValidation
                          ? "Please enter valid name. Remove any special character & number. Name should have 2 to 30 alphabetical characters"
                          : ""}
                      </sub>
                    </Grid>
                    <Grid item xs={6}>
                      <TextField
                        fullWidth
                        color="primary"
                        className="input"
                        id="outlined-required"
                        label="Age"
                        disabled={employeeDetails?.data?.customer_age}
                        type="number"
                        value={customerAge}
                        onBlur={() => verifyAge()}
                        onFocus={() => {
                          setAgeValidation(true);
                        }}
                        onKeyDown={(e) => blockInvalidChar(e)}
                        variant="outlined"
                        required
                        onChange={(e) =>
                          Number(e.target.value) < 110
                            ? setCustomerAge(e.target.value)
                            : ""
                        }
                      />
                      <sub style={{ color: "red" }}>
                        {!ageValidation
                          ? "Please enter valid age, age should be 4+ Years"
                          : ""}
                      </sub>
                    </Grid>
                    <Grid item xs={6}>
                      <TextField
                        fullWidth
                        color="primary"
                        className="input"
                        id="outlined-required"
                        label="Gender"
                        disabled={employeeDetails?.data?.customer_gender}
                        required
                        select
                        value={customerGender}
                        onChange={(e) => setCustomerGender(e.target.value)}
                      >
                        <MenuItem value="male">Male</MenuItem>
                        <MenuItem value="female">Female</MenuItem>
                        <MenuItem value="other">Other</MenuItem>
                      </TextField>
                      <sub style={{ color: "red" }}>
                        {!genderValidation && !customerGender
                          ? "Please select gender"
                          : ""}
                      </sub>
                    </Grid>

                    <Grid item xs={12}>
                      <TextField
                        type="email"
                        label="Email Address"
                        value={customerEmail}
                        onBlur={() => verifyEmail()}
                        onFocus={() => {
                          setEmailValidation(true);
                        }}
                        variant="outlined"
                        fullWidth
                        disabled={verifiedOtpEmail.token}
                        onChange={(e) => setCustomerEmail(e.target.value)}
                      />
                      <sub style={{ color: "red" }}>
                        {!emailValidation ? "Please enter valid e-mail id" : ""}
                      </sub>
                    </Grid>
                    <Grid item xs={12}>
                      <Autocomplete
                        id="customerPackage"
                        key={empPackages}
                        loading={loading}
                        onChange={(event, newValue) => {
                          if (newValue) {
                            let packs: any = [];
                            newValue.map((val: any) => {
                              let obj = JSON.parse(
                                JSON.stringify(val, null, " ")
                              );
                              packs.push(obj);
                            });
                            setCustomerPackages(packs);
                          }
                        }}
                        freeSolo
                        multiple
                        disableClearable
                        options={empPackages || []}
                        limitTags={0}
                        aria-required
                        getOptionLabel={(option: any) => {
                          return (
                            String(option?.name)
                          );
                        }}
                        // defaultValue={customerPackages}
                        getOptionDisabled={(option: any) => {
                          const pack =
                            (customerPackages.length > 0 &&
                              customerPackages.map((val: any) => val.id)) ||
                            [];
                          return pack.length > 0 && pack.includes(option.id);
                        }}
                        renderInput={(params) => (
                          <TextField
                            className="input"
                            {...params}
                            label="Packages"
                            variant="outlined"
                            fullWidth
                            inputProps={{
                              ...params.inputProps,
                              onKeyDown: (e) => {
                                if (e.key === "Enter") {
                                  e.stopPropagation();
                                }
                              },
                            }}
                          />
                        )}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        label="Centre Preference"
                        fullWidth
                        onChange={(e) => setCentreName(e.target.value)}
                        value={centreName}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        label="Additional Tests (If any)"
                        fullWidth
                        onChange={(e) =>
                          setCustomerPackagesAddn(e.target.value)
                        }
                        value={customerPackagesAddn}
                      />
                    </Grid>

                    {file !== "" ? (
                      <>
                        {preview !== "" ? (
                          <>
                            <Grid item xs={8}>
                              Preview
                            </Grid>
                            <Grid item xs={4}>
                              <StyledButtonLight onClick={() => setPreview("")}>
                                Back
                              </StyledButtonLight>
                            </Grid>
                            <Grid item xs={12} style={{ textAlign: "center" }}>
                              <img src={preview} style={{ width: "100%" }} />
                              <span>{file?.name}</span>
                            </Grid>
                          </>
                        ) : (
                          <>
                            <Grid item xs={12}>
                              Uploaded Files
                            </Grid>
                            <Grid item xs={9}>
                              <div
                                style={{
                                  background: "#20B0384F",
                                  padding: 5,
                                  display: "flex",
                                  height: "25px",
                                }}
                              >
                                <span style={{ width: "90%" }}>
                                  {String(file?.name).substring(0, 22)}...
                                </span>
                                <span
                                  style={{
                                    width: "10%",
                                    color: "#20B038",
                                    cursor: "pointer",
                                  }}
                                  onClick={() => setFile("")}
                                >
                                  <CloseIcon />
                                </span>
                              </div>
                            </Grid>
                            <Grid item xs={3} textAlign={"right"}>
                              <Button
                                variant="outlined"
                                color="primary"
                                size="small"
                                onClick={() => handlePreview()}
                                fullWidth
                                style={{ height: 35 }}
                              >
                                Preview
                              </Button>
                            </Grid>
                          </>
                        )}
                      </>
                    ) : (
                      <Grid item xs={12}>
                        <div
                          style={{
                            borderRadius: 6,
                            background: "#E0635820",
                            padding: 10,
                            textAlign: "center",
                          }}
                        >
                          Upload your prescription
                          <StyledButton style={{ marginTop: 5 }}>
                            <input
                              style={{ display: "none" }}
                              id={"fileUp"}
                              type="file"
                              accept="image/png, image/jpg, image/jpeg, application/pdf"
                              onChange={(e) =>
                                setFile(
                                  e.target.files && e.target.files.length > 0
                                    ? e.target.files[0]
                                    : ""
                                )
                              }
                            />
                            <label
                              htmlFor="fileUp"
                              style={{ display: "flex", cursor: "pointer" }}
                            >
                              <div style={{ width: "40%", textAlign: "right" }}>
                                <FileUploadOutlinedIcon
                                  style={{ marginTop: "-3px" }}
                                />
                              </div>
                              <div style={{ width: "60%", textAlign: "left" }}>
                                UPLOAD
                              </div>
                            </label>
                          </StyledButton>
                        </div>
                      </Grid>
                    )}
                  </>
                </Grid>
              </Card>

              <div style={{ margin: "0 15px 15px" }}>
                <StyledButton
                  onClick={() => setOpenSlotModal(true)}
                  disabled={
                    !mobileValidation ||
                    !nameValidation ||
                    !ageValidation ||
                    customerGender === "" ||
                    latitude === "" ||
                    !customerName ||
                    customerPackages.length === 0
                  }
                >
                  NEXT
                </StyledButton>
              </div>
            </Box>
          </>
        )}
        {/* otp verification */}
        <Dialog
          open={otpOpen}
          TransitionComponent={Transition}
          keepMounted
          // onClose={handleClose}
          aria-describedby="alert-dialog-slide-description"
          className="dialogBottom"
        >
          <Grid container spacing={2} className="dialogContent">
            <Grid item xs={12}>
              <Typography component="p" fontWeight={600}>
                Your Details
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                className="input"
                id="outlined-required"
                label="Full Name"
                color="primary"
                value={fullName}
                onChange={(e) =>
                  String(e.target.value).length <= 30
                    ? setFullName(
                        e.target.value.replace(/[^a-zA-Z ]/g, "").trimStart()
                      )
                    : ""
                }
                required
              />
            </Grid>
            <Grid item xs={12}>
              <Grid item xs={12}>
                <TextField
                  key={String(customerNumber)?.length === 10 ? "0" : "1"}
                  type="number"
                  label="Mobile Number"
                  value={customerNumber}
                  variant="outlined"
                  onWheel={(e: any) => e.target.blur()}
                  onInput={(e: any) => {
                    e.target.value = Math.max(0, parseInt(e.target.value))
                      .toString()
                      .slice(0, 10);
                  }}
                  disabled={fullName === ""}
                  fullWidth
                  required
                  onChange={(e) => setCustomerNumber(e.target.value)}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">+91</InputAdornment>
                    ),
                  }}
                />
                <sub style={{ color: "red" }}>
                  {!mobileValidation ? "Please enter valid mobile number" : ""}
                </sub>
              </Grid>
            </Grid>
            {String(customerNumber).length === 10 ? (
              <>
                <Grid item xs={12}>
                  <Typography component="p" color="green">
                    OTP has been shared on your mobile.{" "}
                  </Typography>
                  <Typography component="p">Enter OTP</Typography>
                </Grid>
                <Grid item xs={12}>
                  <OtpInput
                    value={otp}
                    isInputNum={true}
                    numInputs={6}
                    onChange={(e: any) => setOtp(e)}
                    inputStyle={{
                      width: "2rem",
                      height: "2rem",
                      margin: "0 0.4rem",
                      fontSize: "14px",
                      borderRadius: 4,
                      border: "1px solid rgba(0,0,0,0.3)",
                      background: "#e7e7e7",
                      color: "#000",
                    }}
                  />
                </Grid>
                {verifiedOtp?.errors ? (
                  <Grid item xs={12}>
                    <Typography component="p" color="red">
                      <CancelOutlinedIcon
                        style={{
                          color: "red",
                          fontSize: 18,
                          marginBottom: "-3px",
                        }}
                      />{" "}
                      Incorrect OTP!
                    </Typography>
                  </Grid>
                ) : (
                  ""
                )}
                <Grid item xs={12}>
                  {seconds !== 0 ? (
                    <p>
                      Resend OTP in
                      <span style={{ color: "#5866E0" }}>
                        {" "}
                        00:{seconds < 10 ? "0" + seconds : seconds}
                      </span>
                    </p>
                  ) : (
                    <p>
                      <span
                        style={{ color: "#5866E0", cursor: "pointer" }}
                        onClick={() => {
                          PostOtp(customerNumber);
                          setSecond(30);
                        }}
                      >
                        Resend OTP
                      </span>
                    </p>
                  )}
                </Grid>
              </>
            ) : (
              ""
            )}
          </Grid>
        </Dialog>
        {/* //location */}
        <Dialog
          open={locationOpen}
          TransitionComponent={Transition}
          keepMounted
          // onClose={handleCloseLocation}
          aria-describedby="alert-dialog-slide-description"
          className="dialogBottom"
        >
          <Grid container spacing={2} className="dialogContent">
            <Grid item xs={12}>
              <Typography component="p" fontWeight={600}>
                Setup Location
              </Typography>
            </Grid>
            <Grid
              className="pointerHover"
              item
              container
              spacing={2}
              onClick={() => getLatLng()}
            >
              <Grid item xs={1}>
                <GpsFixedIcon />
              </Grid>
              <Grid item xs={9}>
                <Typography component="p">
                  Use Current Location
                  <br />
                  <span style={{ color: "grey", marginBottom: 25 }}>or</span>
                </Typography>
              </Grid>
              <Grid item xs={2}>
                {addrLoading ? <CircularProgress /> : ""}
              </Grid>
            </Grid>

            <Grid item xs={12}>
              <TextField
                color="primary"
                value={addressInput}
                type="text"
                label="Enter your Location Manually"
                className="input"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="start">
                      {<SearchTwoToneIcon style={{ color: "grey" }} />}
                    </InputAdornment>
                  ),
                }}
                onChange={(e) => {
                  clearTimeout(timer.current);
                  timer.current = setTimeout(() => {
                    locationAutoCompleteSearch(e.target.value, e);
                  }, 500);
                  setAddressInput(e.target.value);
                }}
                variant="outlined"
                fullWidth
                required
              />

              <Grid item xs={12}>
                <hr />
              </Grid>
              <Grid style={{ maxHeight: "55vh", overflow: "auto" }}>
                {suggestedAddress.length > 0 &&
                  suggestedAddress.map((option: any) => {
                    return (
                      <Grid container onClick={() => selectAddress(option)}>
                        <Grid container className="locationSelect">
                          <Grid item xs={2} className="centerContainer">
                            <PlaceOutlinedIcon className="centerItem" />
                          </Grid>
                          <Grid item xs={10}>
                            {option?.placeName}, {option?.placeAddress}
                          </Grid>
                        </Grid>
                        <Grid item xs={12}>
                          <hr />
                        </Grid>
                      </Grid>
                    );
                  })}
              </Grid>
            </Grid>
            <Grid item xs={12}></Grid>
          </Grid>
        </Dialog>
        {/* collection slot */}
        <Dialog
          fullScreen={!cancel}
          open={openSlotModal}
          // onClose={() => setOpenSlotModal(false)}
          keepMounted={cancel}
          aria-describedby="alert-dialog-slide-description"
          className={cancel ? "dialogBottom" : ""}
        >
          <CollectionSlotPage
            setOpenSlotModal={setOpenSlotModal}
            setCollectionDate={setCollectionDate}
            collectionDate={collectionDate}
            setCollectionSlot={setCollectionSlot}
            collectionSlot={collectionSlot}
            partnerKey={partnerKey}
            latitude={latitude}
            longitude={longitude}
            loading={loading}
            submitLead={submitLead}
          />
        </Dialog>
        <Dialog fullScreen open={landingPage}>
          <LandingPage
            setLandingPage={setLandingPage}
            setEmpId={setEmpId}
            empId={empId}
            setCustomerEmail={setCustomerEmail}
            customerEmail={customerEmail}
            setOtpEmail={setOtpEmail}
            setOtpEmailVerified={setOtpEmailVerified}
            otpEmail={otpEmail}
          />
        </Dialog>
        {/* confirm page */}
        <Dialog
          fullScreen
          open={openConfirmedModal}
          onClose={() => setOpenConfirmedModal(false)}
        >
          <ConfirmationPage />
        </Dialog>

        <SnackBar val={snackData} />
      </div>
    </ThemeProvider>
  );
}
