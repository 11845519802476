import React, { useState } from "react";
import { useSelector } from "react-redux";
import { Grid, Card, Box, Typography, Container } from "@mui/material";
import "./index.sass";
import moment from "moment";
import Header from "../../components/Header";
import StyledButton from "../../components/Button/StyledButton";
import confirm from "../../images/confirm.png";
import cancelled from "../../images/cancel.svg";
import StyledButtonLight from "../../components/Button/StyledButtonLight";
interface Props {

}

const ConfirmationPage: React.FC<Props> = ({
}) => {
 

  return (
    <Grid className="content" id="collectionSlot">
      <Header isFilter={false} />
      <Box sx={{ flexGrow: 1 }}>
        <Card sx={{ borderRadius: "16px", m: 1 }}>
          <Grid container spacing={2} className="dialogContent">
            <Grid item xs={12} textAlign="center">
              <img src={ confirm} />
            </Grid>
            <Grid item xs={12} textAlign="center">
              <Typography component="h5" variant="h5">
              Thank You
              </Typography>
            </Grid>
            <Grid item xs={12} textAlign="center">
              <Typography component="p">
              Your details have been submitted successfully. Our team member will reach out to you shortly.  
              </Typography>
             
            </Grid>
          </Grid>
        </Card>
      </Box>
    </Grid>
  );
};

export default ConfirmationPage;
