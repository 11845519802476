import React from "react";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import AppBar from "@mui/material/AppBar";
import Typography from "@mui/material/Typography";
import Chip from "@mui/material/Chip";
import { makeStyles } from "@mui/styles";
import { useSelector } from "react-redux";
import TuneOutlinedIcon from "@mui/icons-material/TuneOutlined";
import "./index.css";
import PlaceOutlinedIcon from '@mui/icons-material/PlaceOutlined';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import logo from "../../images/logo1.png"
const useStyles = makeStyles({
  HeaderStyle: {
    background: "#FFFFFF",
  },
});
export default function Header(props: any) {
  const { isFilter, setOpenFilter, addressInput,setLocationOpen,isSupport } = props;
  const classes = useStyles();
  const centreData = useSelector((state: any) => state.webAppSlice.centreByKey);
  const openWhatsapp=()=>{
    window.open("https://wa.me/919654677563", '_blank');
  }
  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar position="static">
        <Toolbar className={classes.HeaderStyle}>
          <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
          <img src={centreData?.logo_image} alt="LOGO" style={{padding:"5px 0px 0px",maxHeight:"44px",maxWidth:"200px"}}/>
          </Typography>
          {isFilter === true ? (
            <TuneOutlinedIcon
              style={{
                color: "grey",
                background: "#EEEEEE",
                padding: 6,
                borderRadius: "50%",
              }}
              onClick={() => setOpenFilter(true)}
            />
          ) : addressInput ? (
            <Chip icon={<PlaceOutlinedIcon style={{fontSize:20}}/>} label={String(addressInput).slice(0, 13) + "..."} onClick={()=>setLocationOpen(true)}/>
          ) :isSupport===true? 
          <Chip icon={<WhatsAppIcon style={{fontSize:20,color:"#25d366"}}/>} label={"WhatsApp Us"} onClick={()=>openWhatsapp()}/>
          :""}
        </Toolbar>
      </AppBar>
    </Box>
  );
}
