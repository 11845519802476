import {
  createSlice,
  createAsyncThunk,
  AsyncThunkAction,
} from "@reduxjs/toolkit";
import Prefix from "../config/ApiPrefix";
import AXIOS from "../config/Axios";

export const getPackagesData = createAsyncThunk(
  "getPackagesData",
  async (obj: any) => {
    try {
      const { data } = await AXIOS.get(
        `${Prefix.api1}/external/v2/center-package-data/${
          obj?.url ? obj?.url : ""
        }`,
        {
          headers: { key: `${obj?.key}` },
        }
      );
      return data;
    } catch (err: any) {
      let title = "";
      if (err.response.data.detail) {
        title = JSON.stringify(err.response.data.detail).replace(
          /"|{|}|[|]/g,
          ""
        );
      } else if (err.response.data.errors) {
        title = JSON.stringify(err.response.data.errors).replace(
          /"|{|}|[|]/g,
          ""
        );
      } else if (err.response.data.message) {
        title = JSON.stringify(err.response.data.message).replace(
          /"|{|}|[|]/g,
          ""
        );
      } else {
        title = "Something went wrong!";
      }
      const data: any = {
        snack: {
          message: title || "Something went wrong",
          status: true,
          type: "error",
        },
      };
      return data;
    }
  }
);
export const getSlots = createAsyncThunk("getSlots", async (obj: any) => {
  try {
    const { data } = await AXIOS.get(
      `${Prefix.api1}/booking/v2/get-time-slot-list/${
        obj?.url ? obj?.url : ""
      }`,
      {
        headers: { key: `${obj?.key}` },
      }
    );
    return data;
  } catch (err: any) {
    let title = "";
    if (err.response.data.detail) {
      title = JSON.stringify(err.response.data.detail).replace(
        /"|{|}|[|]/g,
        ""
      );
    } else if (err.response.data.errors) {
      title = JSON.stringify(err.response.data.errors).replace(
        /"|{|}|[|]/g,
        ""
      );
    } else if (err.response.data.message) {
      title = JSON.stringify(err.response.data.message).replace(/"|{|}|[|]/g, "");
    } else {
      title = "Something went wrong!";
    }
    const data: any = {
      snack: {
        message: title || "Something went wrong",
        status: true,
        type: "error",
      },
    };
    return data;
  }
});
export const getCentreByKey = createAsyncThunk(
  "getCentreByKey",
  async (key: string) => {
    try {
      const { data } = await AXIOS.get(
        `${Prefix.api1}/center/v2/center3p/${key ? key : ""}/`
      );
      return data;
    } catch (err: any) {
      let title = "";
      if (err.response.data.detail) {
        title = JSON.stringify(err.response.data.detail).replace(
          /"|{|}|[|]/g,
          ""
        );
      } else if (err.response.data.errors) {
        title = JSON.stringify(err.response.data.errors).replace(
          /"|{|}|[|]/g,
          ""
        );
      } else if (err.response.data.errors) {
        title = JSON.stringify(err.response.data.error).replace(
          /"|{|}|[|]/g,
          ""
        );
      } else {
        title = "Something went wrong!";
      }
      const data: any = {
        snack: {
          message: title || "Something went wrong",
          status: true,
          type: "error",
        },
      };
      return data;
    }
  }
);
export const postMobileForOtp = createAsyncThunk(
  "postMobileForOtp",
  async (body: any) => {
    try {
      const { data } = await AXIOS.post(
        `${Prefix.api}/notification/send-whatsapp-otp/?otp_type=sms`,
        body
      );
      return data;
    } catch (err: any) {
      let title = "";
      if (err.response.data.detail) {
        title = JSON.stringify(err.response.data.detail).replace(
          /"|{|}|[|]/g,
          ""
        );
      } else if (err.response.data.errors) {
        title = JSON.stringify(err.response.data.errors).replace(
          /"|{|}|[|]/g,
          ""
        );
      } else if (err.response.data.errors) {
        title = JSON.stringify(err.response.data.error).replace(
          /"|{|}|[|]/g,
          ""
        );
      } else {
        title = "Something went wrong!";
      }
      const data: any = {
        snack: {
          message: title || "Something went wrong",
          status: true,
          type: "error",
        },
      };
      return data;
    }
  }
);
export const postVerifyOtp = createAsyncThunk(
  "postVerifyOtp",
  async (body: any) => {
    try {
      const { data } = await AXIOS.post(
        `${Prefix.api}/notification/verify-whatsapp-otp/`,
        body
      );
      return data;
    } catch (err: any) {
      let title = "";
      if (err.response.data.detail) {
        title = JSON.stringify(err.response.data.detail).replace(
          /"|{|}|[|]/g,
          ""
        );
      } else if (err.response.data.errors) {
        title = JSON.stringify(err.response.data.errors).replace(
          /"|{|}|[|]/g,
          ""
        );
      } else if (err.response.data.errors) {
        title = JSON.stringify(err.response.data.error).replace(
          /"|{|}|[|]/g,
          ""
        );
      } else {
        title = "Something went wrong!";
      }
      const data: any = {
        snack: {
          message: title || "Something went wrong",
          status: true,
          type: "error",
        },
      };
      return data;
    }
  }
);
export const checkGeoFenchMapMyIndia = createAsyncThunk(
  "checkGeoFenchMapMyIndia",
  async (val: any) => {
    try {
      const { data } = await AXIOS.get(
        `${Prefix.api}/booking/checking-geofence-area-mapmyindia_2/?eloc=${val.eloc}`
      );
      return data;
    } catch (err: any) {
      let title = "";
      if (err.response.data.detail) {
        title = JSON.stringify(err.response.data.detail).replace(
          /"|{|}|[|]/g,
          ""
        );
      } else if (err.response.data.errors) {
        title = JSON.stringify(err.response.data.errors).replace(
          /"|{|}|[|]/g,
          ""
        );
      } else if (err.response.data.errors) {
        title = JSON.stringify(err.response.data.error).replace(
          /"|{|}|[|]/g,
          ""
        );
      } else {
        title = "Something went wrong!";
      }
      const data: any = {
        snack: {
          message: "The entered location is non-serviceable. Our backend team will connect with you." || "Something went wrong",
          status: true,
          type: "error",
        },
      };
      return data;
    }
  }
);
export const getMapMyIndiaAccessToken = createAsyncThunk(
  "getMapMyIndiaAccessToken",
  async () => {
    try {
      const { data } = await AXIOS.get(
        `${Prefix.api}/panel/open-mapmyindia-auth-token/?token=b4485893643d8fdd10577e77cff3c860`
      );
      return data;
    } catch (err: any) {
      let title = "";
      if (err.response.data.detail) {
        title = JSON.stringify(err.response.data.detail).replace(
          /"|{|}|[|]/g,
          ""
        );
      } else if (err.response.data.errors) {
        title = JSON.stringify(err.response.data.errors).replace(
          /"|{|}|[|]/g,
          ""
        );
      } else if (err.response.data.errors) {
        title = JSON.stringify(err.response.data.error).replace(
          /"|{|}|[|]/g,
          ""
        );
      } else {
        title = "Something went wrong!";
      }
      const data: any = {
        snack: {
          message: title || "Something went wrong",
          status: true,
          type: "error",
        },
      };
      return data;
    }
  }
);
export const getPartnerKey = createAsyncThunk(
  "getPartnerKey",
  async (cid: any) => {
    try {
      const { data } = await AXIOS.get(`${Prefix.api}/partner/3p-key/${cid}/`);
      return data;
    } catch (err: any) {
      let title = "";
      if (err.response.data.detail) {
        title = JSON.stringify(err.response.data.detail).replace(
          /"|{|}|[|]/g,
          ""
        );
      } else if (err.response.data.errors) {
        title = JSON.stringify(err.response.data.errors).replace(
          /"|{|}|[|]/g,
          ""
        );
      } else if (err.response.data.errors) {
        title = JSON.stringify(err.response.data.error).replace(
          /"|{|}|[|]/g,
          ""
        );
      } else {
        title = "Something went wrong!";
      }
      const data: any = {
        snack: {
          message: title || "Something went wrong",
          status: true,
          type: "error",
        },
      };
      return data;
    }
  }
);
export const getLocationrRevGeoCode = createAsyncThunk(
  "getLocationrRevGeoCode",
  async (val: any) => {
    try {
      const { data } = await AXIOS.get(
        `https://apis.mapmyindia.com/advancedmaps/v1/${val?.token}/rev_geocode?&lng=${val?.lng}&lat=${val?.lat}`
      );
      return data;
    } catch (err: any) {
      let title = "";
      if (err.response.data.detail) {
        title = JSON.stringify(err.response.data.detail).replace(
          /"|{|}|[|]/g,
          ""
        );
      } else if (err.response.data.errors) {
        title = JSON.stringify(err.response.data.errors).replace(
          /"|{|}|[|]/g,
          ""
        );
      } else if (err.response.data.errors) {
        title = JSON.stringify(err.response.data.error).replace(
          /"|{|}|[|]/g,
          ""
        );
      } else {
        title = "Something went wrong!";
      }
      const data: any = {
        snack: {
          message: title || "Something went wrong",
          status: true,
          type: "error",
        },
      };
      return data;
    }
  }
);
export const createBooking = createAsyncThunk(
  "createBooking",
  async (body: any) => {
    try {
      const { data } = await AXIOS.post(
        `${Prefix.api1}/external/v2/center-create-booking/`,
        body.data,
        {
          headers: { key: `${body?.key}` },
        }
      );
      return data;
    } catch (err: any) {
      let title = "";
      if (err.response.data.message) {
        title = JSON.stringify(err.response.data.message).replace(
          /"|{|}|[|]/g,
          ""
        );
      } else if (err.response.data.errors) {
        title = JSON.stringify(err.response.data.errors).replace(
          /"|{|}|[|]/g,
          ""
        );
      } else if (err.response.data.errors) {
        title = JSON.stringify(err.response.data.error).replace(
          /"|{|}|[|]/g,
          ""
        );
      } else {
        title = "Something went wrong!";
      }
      const data: any = {
        snack: {
          message: title || "Something went wrong",
          status: true,
          type: "error",
        },
      };
      return data;
    }
  }
);
export const uploadPrescription = createAsyncThunk(
  "uploadPrescription",
  async (body: any) => {
    try {
      const { data } = await AXIOS.post(
        `${Prefix.api1}/external/v1/prescription-upload-lead/`,
        body.data,
        {
          headers: { key: `${body?.key}` },
        }
      );
      return data;
    } catch (err: any) {
      let title = "";
      if (err.response.data.message) {
        title = JSON.stringify(err.response.data.message).replace(
          /"|{|}|[|]/g,
          ""
        );
      } else if (err.response.data.errors) {
        title = JSON.stringify(err.response.data.errors).replace(
          /"|{|}|[|]/g,
          ""
        );
      } else if (err.response.data.errors) {
        title = JSON.stringify(err.response.data.error).replace(
          /"|{|}|[|]/g,
          ""
        );
      } else {
        title = "Something went wrong!";
      }
      const data: any = {
        snack: {
          message: title || "Something went wrong",
          status: true,
          type: "error",
        },
      };
      return data;
    }
  }
);
export const getValidLocation = createAsyncThunk(
  "getValidLocation",
  async (obj: any) => {
    try {
      const { data } = await AXIOS.get(
        `${Prefix.api1}/center/v2/is-location-serviceable/${
          obj?.url ? obj?.url : ""
        }`,
        {
          headers: { key: `${obj?.key}` },
        }
      );
      return data;
    } catch (err: any) {
      let title = "";
      if (err.response.data.detail) {
        title = JSON.stringify(err.response.data.detail).replace(
          /"|{|}|[|]/g,
          ""
        );
      } else if (err.response.data.errors) {
        title = JSON.stringify(err.response.data.errors).replace(
          /"|{|}|[|]/g,
          ""
        );
      } else if (err.response.data.message) {
        title = JSON.stringify(err.response.data.message).replace(
          /"|{|}|[|]/g,
          ""
        );
      } else {
        title = "Something went wrong!";
      }
      const data: any = {
        snack: {
          message: "The entered location is non-serviceable. Our backend team will connect with you." || "Something went wrong",
          status: true,
          type: "error",
        },
      };
      return data;
    }
  }
);
export const getZone = createAsyncThunk(
  "getZone",
  async (obj: any) => {
    try {
      const { data } = await AXIOS.get(
        `${Prefix.api1}/phlebo/v2/get-zone/${
          obj?.url ? obj?.url : ""
        }`,
        {
          headers: { key: `${obj?.key}` },
        }
      );
      return data;
    } catch (err: any) {
      let title = "";
      if (err.response.data.detail) {
        title = JSON.stringify(err.response.data.detail).replace(
          /"|{|}|[|]/g,
          ""
        );
      } else if (err.response.data.errors) {
        title = JSON.stringify(err.response.data.errors).replace(
          /"|{|}|[|]/g,
          ""
        );
      } else if (err.response.data.message) {
        title = JSON.stringify(err.response.data.message).replace(
          /"|{|}|[|]/g,
          ""
        );
      } else {
        title = "Something went wrong!";
      }
      const data: any = {
        snack: {
          message: "The entered location is non-serviceable. Our backend team will connect with you." || "Something went wrong",
          status: true,
          type: "error",
        },
      };
      return data;
    }
  }
);
export const getCouponCalc = createAsyncThunk(
  "getCouponCalc",
  async (obj: any) => {
    try {
      const { data } = await AXIOS.get(
        `${Prefix.api}/booking/get_prices/${
          obj?.url ? obj?.url : ""
        }`,
        {
          headers: { key: `${obj?.key}` },
        }
      );
      return data;
    } catch (err: any) {
      let title = "";
      if (err.response.data.detail) {
        title = JSON.stringify(err.response.data.detail).replace(
          /"|{|}|[|]/g,
          ""
        );
      } else if (err.response.data.errors) {
        title = JSON.stringify(err.response.data.errors).replace(
          /"|{|}|[|]/g,
          ""
        );
      } else if (err.response.data.message) {
        title = JSON.stringify(err.response.data.message).replace(
          /"|{|}|[|]/g,
          ""
        );
      } else {
        title = "Something went wrong!";
      }
      const data: any = {
        snack: {
          message: title || "Something went wrong",
          status: true,
          type: "error",
        },
      };
      return data;
    }
  }
);
export const getCoupon = createAsyncThunk(
  "getCoupon",
  async (obj: any) => {
    try {
      const { data } = await AXIOS.get(
        `${Prefix.api}/coupon/package-coupon/${
          obj?.url ? obj?.url : ""
        }`,
        {
          headers: { key: `${obj?.key}` },
        }
      );
      return data;
    } catch (err: any) {
      let title = "";
      if (err.response.data.detail) {
        title = JSON.stringify(err.response.data.detail).replace(
          /"|{|}|[|]/g,
          ""
        );
      } else if (err.response.data.errors) {
        title = JSON.stringify(err.response.data.errors).replace(
          /"|{|}|[|]/g,
          ""
        );
      } else if (err.response.data.message) {
        title = JSON.stringify(err.response.data.message).replace(
          /"|{|}|[|]/g,
          ""
        );
      } else {
        title = "Something went wrong!";
      }
      const data: any = {
        snack: {
          message: title || "Something went wrong",
          status: true,
          type: "error",
        },
      };
      return data;
    }
  }
);
export const getBooking = createAsyncThunk(
  "getBooking",
  async (obj: any) => {
    try {
      const { data } = await AXIOS.get(
        `${Prefix.api1}/external/v2/center-get-booking/${
          obj?.url ? obj?.url : ""
        }`,
        {
          headers: { key: `${obj?.key}` },
        }
      );
      return data;
    } catch (err: any) {
      let title = "";
      if (err.response.data.detail) {
        title = JSON.stringify(err.response.data.detail).replace(
          /"|{|}|[|]/g,
          ""
        );
      } else if (err.response.data.errors) {
        title = JSON.stringify(err.response.data.errors).replace(
          /"|{|}|[|]/g,
          ""
        );
      } else if (err.response.data.message) {
        title = JSON.stringify(err.response.data.message).replace(
          /"|{|}|[|]/g,
          ""
        );
      } else {
        title = "Something went wrong!";
      }
      const data: any = {
        snack: {
          message: title || "Something went wrong",
          status: true,
          type: "error",
        },
      };
      return data;
    }
  }
);
export const updateBooking = createAsyncThunk(
  "updateBooking",
  async (body: any) => {
    try {
      const { data } = await AXIOS.post(
        `${Prefix.api1}/external/v2/center-update-booking/`,
        body.data,
        {
          headers: { key: `${body?.key}` },
        }
      );
      return data;
    } catch (err: any) {
      let title = "";
      if (err.response.data.message) {
        title = JSON.stringify(err.response.data.message).replace(
          /"|{|}|[|]/g,
          ""
        );
      } else if (err.response.data.errors) {
        title = JSON.stringify(err.response.data.errors).replace(
          /"|{|}|[|]/g,
          ""
        );
      } else if (err.response.data.errors) {
        title = JSON.stringify(err.response.data.error).replace(
          /"|{|}|[|]/g,
          ""
        );
      } else {
        title = "Something went wrong!";
      }
      const data: any = {
        snack: {
          message: title || "Something went wrong",
          status: true,
          type: "error",
        },
      };
      return data;
    }
  }
);
export const resetState = createAsyncThunk(
  "resetState",
  async () => {
    try {
      return null;
    } catch (err: any) {
      return null;
    }
  }
);
export const createLead = createAsyncThunk(
  "createLead",
  async (body: any) => {
    try {
      const { data } = await AXIOS.post(
        `${Prefix.api1}/lead/v2/lead/`,
        body.data,
        {
          headers: { key: `${body?.key}` },
        }
      );
      return data;
    } catch (err: any) {
      let title = "";
      if (err.response.data.message) {
        title = JSON.stringify(err.response.data.message).replace(
          /"|{|}|[|]/g,
          ""
        );
      } else if (err.response.data.errors) {
        title = JSON.stringify(err.response.data.errors).replace(
          /"|{|}|[|]/g,
          ""
        );
      } else if (err.response.data.errors) {
        title = JSON.stringify(err.response.data.error).replace(
          /"|{|}|[|]/g,
          ""
        );
      } else {
        title = "Something went wrong!";
      }
      const data: any = {
        snack: {
          message: title || "Something went wrong",
          status: true,
          type: "error",
        },
      };
      return data;
    }
  }
);
export const updateLead = createAsyncThunk(
  "updateLead",
  async (body: any) => {
    try {
      const { data } = await AXIOS.put(
        `${Prefix.api1}/lead/v2/lead/${body.id}/`,
        body.data,
        {
          headers: { key: `${body?.key}` },
        }
      );
      return data;
    } catch (err: any) {
      let title = "";
      if (err.response.data.message) {
        title = JSON.stringify(err.response.data.message).replace(
          /"|{|}|[|]/g,
          ""
        );
      } else if (err.response.data.errors) {
        title = JSON.stringify(err.response.data.errors).replace(
          /"|{|}|[|]/g,
          ""
        );
      } else if (err.response.data.errors) {
        title = JSON.stringify(err.response.data.error).replace(
          /"|{|}|[|]/g,
          ""
        );
      } else {
        title = "Something went wrong!";
      }
      const data: any = {
        snack: {
          message: title || "Something went wrong",
          status: true,
          type: "error",
        },
      };
      return data;
    }
  }
);
export const submitReview = createAsyncThunk(
  "submitReview",
  async (body: any) => {
    try {
      const { data } = await AXIOS.post(
        `${Prefix.api}/booking/booking-feedback-create/?key=${body.key}`,
        body.data
      );
      return data;
    } catch (err: any) {
      let title = "";
      if (err.response.data.message) {
        title = JSON.stringify(err.response.data.message).replace(
          /"|{|}|[|]/g,
          ""
        );
      } else if (err.response.data.errors) {
        title = JSON.stringify(err.response.data.errors).replace(
          /"|{|}|[|]/g,
          ""
        );
      } else if (err.response.data.errors) {
        title = JSON.stringify(err.response.data.error).replace(
          /"|{|}|[|]/g,
          ""
        );
      } else {
        title = "Something went wrong!";
      }
      const data: any = {
        snack: {
          message: title || "Something went wrong",
          status: true,
          type: "error",
        },
      };
      return data;
    }
  }
);
export const updateBookingEmail = createAsyncThunk(
  "updateBookingEmail",
  async (body: any) => {
    try {
      const { data } = await AXIOS.put(
        `${Prefix.api}/booking/open-data/${body.id}/?key=${body.key}`,
        body.data
      );
      return data;
    } catch (err: any) {
      let title = "";
      if (err.response.data.message) {
        title = JSON.stringify(err.response.data.message).replace(
          /"|{|}|[|]/g,
          ""
        );
      } else if (err.response.data.errors) {
        title = JSON.stringify(err.response.data.errors).replace(
          /"|{|}|[|]/g,
          ""
        );
      } else if (err.response.data.errors) {
        title = JSON.stringify(err.response.data.error).replace(
          /"|{|}|[|]/g,
          ""
        );
      } else {
        title = "Something went wrong!";
      }
      const data: any = {
        snack: {
          message: title || "Something went wrong",
          status: true,
          type: "error",
        },
      };
      return data;
    }
  }
);
export const getPrevBooking = createAsyncThunk(
  "getPrevBooking",
  async (obj: any) => {
    try {
      const { data } = await AXIOS.get(
        `${Prefix.api1}/external/v2/center-get-booking/${
          obj?.url ? obj?.url : ""
        }`,
        {
          headers: { key: `${obj?.key}` },
        }
      );
      return data;
    } catch (err: any) {
      let title = "";
      if (err.response.data.detail) {
        title = JSON.stringify(err.response.data.detail).replace(
          /"|{|}|[|]/g,
          ""
        );
      } else if (err.response.data.errors) {
        title = JSON.stringify(err.response.data.errors).replace(
          /"|{|}|[|]/g,
          ""
        );
      } else if (err.response.data.message) {
        title = JSON.stringify(err.response.data.message).replace(
          /"|{|}|[|]/g,
          ""
        );
      } else {
        title = "Something went wrong!";
      }
      const data: any = {
        snack: {
          message: title || "Something went wrong",
          status: true,
          type: "error",
        },
      };
      return data;
    }
  }
);
export const getEmailOtp = createAsyncThunk(
  "getEmailOtp",
  async (key: string) => {
    try {
      const { data } = await AXIOS.get(
        `${Prefix.api1}/external/v2/send-custom-iframe-otp/${key ? key : ""}`
      );
      return data;
    } catch (err: any) {
      let title = "";
      if (err.response.data.detail) {
        title = JSON.stringify(err.response.data.detail).replace(
          /"|{|}|[|]/g,
          ""
        );
      } else if (err.response.data.errors) {
        title = JSON.stringify(err.response.data.errors).replace(
          /"|{|}|[|]/g,
          ""
        );
      } else if (err.response.data.errors) {
        title = JSON.stringify(err.response.data.error).replace(
          /"|{|}|[|]/g,
          ""
        );
      } else {
        title = "Something went wrong!";
      }
      const data: any = {
        snack: {
          message: title || "Something went wrong",
          status: true,
          type: "error",
        },
      };
      return data;
    }
  }
);
export const postOtpForEmail = createAsyncThunk(
  "postOtpForEmail",
  async (key: string) => {
    try {
      const { data } = await AXIOS.get(
        `${Prefix.api1}/external/v2/validate-iframe-otp/${key ? key : ""}`
      );
      return data;
    } catch (err: any) {
      let title = "";
      if (err.response.data.detail) {
        title = JSON.stringify(err.response.data.detail).replace(
          /"|{|}|[|]/g,
          ""
        );
      } else if (err.response.data.errors) {
        title = JSON.stringify(err.response.data.errors).replace(
          /"|{|}|[|]/g,
          ""
        );
      } else if (err.response.data.errors) {
        title = JSON.stringify(err.response.data.error).replace(
          /"|{|}|[|]/g,
          ""
        );
      } else {
        title = "Something went wrong!";
      }
      const data: any = {
        snack: {
          message: title || "Something went wrong",
          status: true,
          type: "error",
        },
      };
      return data;
    }
  }
);
export const getEmpDetails = createAsyncThunk(
  "getEmpDetails",
  async (key: string) => {
    try {
      const { data } = await AXIOS.get(
        `${Prefix.api1}/external/v2/validate-iframe-token/`,
        {
          headers: { key: key },
        }
      );
      return data;
    } catch (err: any) {
      let title = "";
      if (err.response.data.detail) {
        title = JSON.stringify(err.response.data.detail).replace(
          /"|{|}|[|]/g,
          ""
        );
      } else if (err.response.data.errors) {
        title = JSON.stringify(err.response.data.errors).replace(
          /"|{|}|[|]/g,
          ""
        );
      } else if (err.response.data.errors) {
        title = JSON.stringify(err.response.data.error).replace(
          /"|{|}|[|]/g,
          ""
        );
      } else {
        title = "Something went wrong!";
      }
      const data: any = {
        snack: {
          message: title || "Something went wrong",
          status: true,
          type: "error",
        },
      };
      return data;
    }
  }
);
export const removeEmpToken = createAsyncThunk(
  "removeEmpToken",
  async (key: string) => {
    try {
      const { data } = await AXIOS.get(
        `${Prefix.api1}/external/v2/set-is-used/`,
        {
          headers: { key: key },
        }
      );
      return data;
    } catch (err: any) {
      let title = "";
      if (err.response.data.detail) {
        title = JSON.stringify(err.response.data.detail).replace(
          /"|{|}|[|]/g,
          ""
        );
      } else if (err.response.data.errors) {
        title = JSON.stringify(err.response.data.errors).replace(
          /"|{|}|[|]/g,
          ""
        );
      } else if (err.response.data.errors) {
        title = JSON.stringify(err.response.data.error).replace(
          /"|{|}|[|]/g,
          ""
        );
      } else {
        title = "Something went wrong!";
      }
      const data: any = {
        snack: {
          message: title || "Something went wrong",
          status: true,
          type: "error",
        },
      };
      return data;
    }
  }
);
//reducers
const initialState = {
  data: {},
  loading: true,
  packageParams: [],
  mobileForOtp: [],
  centreByKey: [],
  verifyOtp: [],
  geoFencesMapMyIndia: {},
  mapmyindiaAccessToken: {},
  partnerKey: {},
  slots: [],
  revGeoCode: [],
  bookingCreated: {},
  snackbarData: {},
  uploadPrescriptionData:{},
  validLocation:{},
  bookingData:{},
  prevBookingData:{},
  bookingUpdated: {},
  bookingUpdatedEmail: {},
  zone: {},
  couponCalc: {},
  coupon:{},
  leadCreated:{},
  emailOtp:{},
  emailOtpVerified:{},
empDetails:{}
};
const webAppSlice = createSlice({
  name: "webAppSlice",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
     
      .addCase(getPackagesData.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(getPackagesData.fulfilled, (state, action) => {
        if (action.payload.snack) {
          state.snackbarData = action.payload.snack;
        } else {
          state.packageParams = action.payload;
        }
        state.loading = false;
      })
      .addCase(getCoupon.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(getCoupon.fulfilled, (state, action) => {
        if (action.payload.snack) {
          state.snackbarData = action.payload.snack;
        } else {
          state.coupon = action.payload;
        }
        state.loading = false;
      })
      .addCase(postMobileForOtp.fulfilled, (state, action) => {
        if (action.payload.snack) {
          state.snackbarData = action.payload.snack;
        } else {
          state.mobileForOtp = action.payload;
        }
      })
      .addCase(getCentreByKey.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(getCentreByKey.fulfilled, (state, action) => {
        if (action.payload.snack) {
          state.snackbarData = action.payload.snack;
        } else {
          state.centreByKey = action.payload;
        }
        state.loading = false;
      })
      .addCase(postVerifyOtp.fulfilled, (state, action) => {
        if (action.payload.snack) {
          state.snackbarData = action.payload.snack;
        } else {
          state.verifyOtp = action.payload;
        }
      })
      .addCase(checkGeoFenchMapMyIndia.fulfilled, (state, action) => {
        if (action.payload.snack) {
          state.snackbarData = action.payload.snack;
          state.geoFencesMapMyIndia = {status:false};
        } else {
          state.geoFencesMapMyIndia = action.payload;
        }
      })
      .addCase(getMapMyIndiaAccessToken.fulfilled, (state, action) => {
        if (action.payload.snack) {
          state.snackbarData = action.payload.snack;
        } else {
          state.mapmyindiaAccessToken = action.payload;
        }
      })
      .addCase(getPartnerKey.fulfilled, (state, action) => {
        if (action.payload.snack) {
          state.snackbarData = action.payload.snack;
        } else {
          state.partnerKey = action.payload;
        }
      })
      .addCase(getSlots.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(getSlots.fulfilled, (state, action) => {
        if (action.payload.snack) {
          state.snackbarData = action.payload.snack;
        } else {
          state.slots = action.payload;
        }
        state.loading = false;
      })
      .addCase(getLocationrRevGeoCode.fulfilled, (state, action) => {
        state.revGeoCode = action.payload;
      })
      .addCase(createBooking.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(createLead.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(createLead.fulfilled, (state, action) => {
        if (action.payload.snack) {
          state.snackbarData = action.payload.snack;
        } else {
          state.leadCreated = action.payload;
        }
        state.loading = false;
      })
      .addCase(createBooking.fulfilled, (state, action) => {
        if (action.payload.snack) {
          state.snackbarData = action.payload.snack;
        } else {
          state.bookingCreated = action.payload;
        }
        state.loading = false;
      })
      .addCase(getCouponCalc.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(getCouponCalc.fulfilled, (state, action) => {
        if (action.payload.snack) {
          state.snackbarData = action.payload.snack;
        } else {
          state.couponCalc = action.payload;
        }
        state.loading = false;
      })
      .addCase(uploadPrescription.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(uploadPrescription.fulfilled, (state, action) => {
        if (action.payload.snack) {
          state.snackbarData = action.payload.snack;
        } else {
          state.uploadPrescriptionData = action.payload;
        }
        state.loading = false;
      })
      .addCase(getValidLocation.fulfilled, (state, action) => {
        if (action.payload.snack) {
          state.snackbarData = action.payload.snack;
          state.validLocation = {valid:false};
        } else {
          state.validLocation = {valid:true};
        }
        state.loading = false;
      })
      .addCase(getBooking.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(getBooking.fulfilled, (state, action) => {
        if (action.payload.snack) {
          state.snackbarData = action.payload.snack;
          state.bookingData = {};
        } else {
          state.bookingData = action.payload;
        }
        state.loading = false;
      })
      .addCase(getPrevBooking.fulfilled, (state, action) => {
          state.prevBookingData = action.payload;
      })
      .addCase(updateBooking.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(updateBooking.fulfilled, (state, action) => {
        if (action.payload.snack) {
          state.snackbarData = action.payload.snack;
        } else {
          state.bookingUpdated = action.payload;
        }
        state.loading = false;
      })
      .addCase(resetState.fulfilled, (state, action) => {
          state.bookingUpdated = {};
        state.loading = false;
      })
      .addCase(getZone.fulfilled, (state, action) => {
        state.zone = action.payload;
      })
      .addCase(updateBookingEmail.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(updateBookingEmail.fulfilled, (state, action) => {
        if (action.payload.snack) {
          state.snackbarData = action.payload.snack;
        } else {
          state.bookingUpdatedEmail = action.payload;
        }
        state.loading = false;
      })
      .addCase(getEmailOtp.pending, (state, action) => {
        state.emailOtp = true;
      })
      .addCase(getEmailOtp.fulfilled, (state, action) => {
        if (action.payload.snack) {
          state.snackbarData = action.payload.snack;
        } else {
          state.emailOtp = action.payload;
        }
        state.loading = false;
      })
      .addCase(postOtpForEmail.fulfilled, (state, action) => {
        if (action.payload.snack) {
          state.emailOtpVerified = action.payload.snack;
        } else {
          state.emailOtpVerified = action.payload;
        }
       
      })
      .addCase(getEmpDetails.fulfilled, (state, action) => {
        if (action.payload.snack) {
          state.empDetails = action.payload.snack;
        } else {
          state.empDetails = action.payload;
        }
       
      })
      ;
  },
});

export default webAppSlice.reducer;
