import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, Grid, Card, Box, Typography } from "@mui/material";
import "./index.sass";
import ExpandLessOutlinedIcon from "@mui/icons-material/ExpandLessOutlined";
import ExpandMoreOutlinedIcon from "@mui/icons-material/ExpandMoreOutlined";
import {
  getSlots,
} from "../../services/actionSlice";
import moment from "moment";
import Header from "../../components/Header";
import { AppDispatch } from "../../storage/store";
import StyledButton from "../../components/Button/StyledButton";
import morning from "../../images/morning.png";
import afternoon from "../../images/afternoon.png";
import evening from "../../images/evening.png";
import Loader from "../../components/Loader";
import StyledButtonLight from "../../components/Button/StyledButtonLight";
interface Props {
  setOpenSlotModal: any;
  setCollectionDate: any;
  collectionDate: any;
  setCollectionSlot: any;
  collectionSlot: any;
  partnerKey: any;
  latitude: any;
  longitude: any;
  loading: any;
  submitLead: Function;
}

const CollectionSlotPage: React.FC<Props> = ({
  setOpenSlotModal,
  setCollectionDate,
  collectionDate,
  setCollectionSlot,
  collectionSlot,
  partnerKey,
  latitude,
  longitude,
  loading,
  submitLead,
}) => {
  const dispatch = useDispatch<AppDispatch>();
  const slots = useSelector((state: any) => state.webAppSlice.slots);
  const [slotType, setSlotType] = useState<any>("morning");
  const [morningSlots, setMorningSlots] = useState<any>([]);
  const [afterNoonSlots, setAfterNoonSlots] = useState<any>([]);
  const [eveningSlots, setEveningSlots] = useState<any>([]);

  useEffect(() => {
    if (
      collectionDate &&
      collectionDate >= moment().format("YYYY-MM-DD") &&
      partnerKey?.center_key &&
      latitude &&
      longitude
    ) {
      dispatch(
        getSlots({
          url: `?collection_date=${collectionDate}&latitude=${latitude}&longitude=${longitude}&booking_type=imaging`,
          key: partnerKey?.center_key,
        })
      );
    }
  }, [collectionDate, partnerKey, latitude]);
  useEffect(() => {
    if (morningSlots.length > 0) {
      setSlotType("morning");
    } else if (afterNoonSlots.length > 0) {
      setSlotType("afternoon");
    } else if (eveningSlots.length > 0) {
      setSlotType("evening");
    } else {
      setSlotType("");
    }
  }, [afterNoonSlots, morningSlots, eveningSlots]);

  useEffect(() => {
    if (slots && slots.results && slots.results.length > 0) {
      let arr: any = [];

      arr = [...slots.results];

      let m: any = [];
      let a: any = [];
      let e: any = [];
      arr.length > 0 &&
        arr?.map((val: any) => {
          if (Number(String(val.format_24_hrs?.start_time).slice(0, 2)) < 12) {
            m.push(val);
          } else if (
            Number(String(val.format_24_hrs?.start_time).slice(0, 2)) > 11 &&
            Number(String(val.format_24_hrs?.start_time).slice(0, 2)) < 18
          ) {
            a.push(val);
          } else {
            e.push(val);
          }
        });
      setAfterNoonSlots(a);
      setMorningSlots(m);
      setEveningSlots(e);
    } else if (slots && slots.results && slots.results.length === 0) {
      setAfterNoonSlots([]);
      setMorningSlots([]);
      setEveningSlots([]);
    }
  }, [slots]);

  return (
    <Grid className="contentCC" id={"collectionSlot"}>
      <Header isFilter={false} />

      <Box sx={{ flexGrow: 1 }}>
        <Card sx={{ borderRadius: "16px", m: 1 }}>
          <Grid container spacing={2} className="dialogContent">
            <Grid item xs={12}>
              <Typography component="p">SELECT DATE</Typography>
            </Grid>
       
            <Grid item xs={3}>
              <div
                className={
                  moment().add(3, "days").format("YYYY-MM-DD") ===
                  collectionDate
                    ? "datePro"
                    : "dateLite"
                }
                onClick={() => {
                  setCollectionDate(
                    moment().add(3, "days").format("YYYY-MM-DD")
                  );
                  setCollectionSlot("");
                }}
              >
                <sub>{moment().add(3, "days").format("MMM")}</sub>
                <p>{moment().add(3, "days").format("DD")}</p>
                <sup>{moment().add(3, "days").format("ddd")}</sup>
              </div>
            </Grid>
            <Grid item xs={3}>
              <div
                className={
                  moment().add(4, "days").format("YYYY-MM-DD") ===
                  collectionDate
                    ? "datePro"
                    : "dateLite"
                }
                onClick={() => {
                  setCollectionDate(
                    moment().add(4, "days").format("YYYY-MM-DD")
                  );
                  setCollectionSlot("");
                }}
              >
                <sub>{moment().add(4, "days").format("MMM")}</sub>
                <p>{moment().add(4, "days").format("DD")}</p>
                <sup>{moment().add(4, "days").format("ddd")}</sup>
              </div>{" "}
            </Grid>
            <Grid item xs={3}>
              <div
                className={
                  moment().add(5, "days").format("YYYY-MM-DD") ===
                  collectionDate
                    ? "datePro"
                    : "dateLite"
                }
                onClick={() => {
                  setCollectionDate(
                    moment().add(5, "days").format("YYYY-MM-DD")
                  );
                  setCollectionSlot("");
                }}
              >
                <sub>{moment().add(5, "days").format("MMM")}</sub>
                <p>{moment().add(5, "days").format("DD")}</p>
                <sup>{moment().add(5, "days").format("ddd")}</sup>
              </div>{" "}
            </Grid>
            <Grid item xs={3}>
              <div
                className={
                  moment().add(6, "days").format("YYYY-MM-DD") ===
                  collectionDate
                    ? "datePro"
                    : "dateLite"
                }
                onClick={() => {
                  setCollectionDate(
                    moment().add(6, "days").format("YYYY-MM-DD")
                  );
                  setCollectionSlot("");
                }}
              >
                <sub>{moment().add(6, "days").format("MMM")}</sub>
                <p>{moment().add(6, "days").format("DD")}</p>
                <sup>{moment().add(6, "days").format("ddd")}</sup>
              </div>{" "}
            </Grid>
          </Grid>
        </Card>
        <Card sx={{ borderRadius: "16px", m: 1 }}>
          <Grid container spacing={2} className="dialogContent">
            <Grid item xs={12}>
              <Typography component="p">SELECT TIME SLOT</Typography>
            </Grid>
            {loading ? (
              <Loader />
            ) : (
              <>
                <Grid container className="borderBox">
                  <Grid item xs={10}>
                    <img src={morning} /> Morning
                  </Grid>
                  <Grid item xs={2} textAlign="right">
                    {slotType === "morning" ? (
                      <ExpandLessOutlinedIcon onClick={() => setSlotType("")} />
                    ) : (
                      <ExpandMoreOutlinedIcon
                        onClick={() => setSlotType("morning")}
                      />
                    )}
                  </Grid>
                  {slotType === "morning" ? (
                    <Grid container>
                      {morningSlots.length > 0 &&
                        morningSlots?.map((val: any) => (
                          <Grid item xs={6} textAlign="center">
                            <Button
                              variant={
                                collectionSlot?.id === val.id
                                  ? "contained"
                                  : "outlined"
                              }
                              size="small"
                              color="primary"
                              onClick={() => setCollectionSlot(val)}
                            >
                              {val?.format_12_hrs?.start_time} -{" "}
                              {val?.format_12_hrs?.end_time}
                            </Button>
                          </Grid>
                        ))}
                    </Grid>
                  ) : (
                    ""
                  )}
                </Grid>

                <Grid container className="borderBox">
                  <Grid item xs={10}>
                    <img src={afternoon} /> Afternoon
                  </Grid>
                  <Grid item xs={2} textAlign="right">
                    {slotType === "afternoon" ? (
                      <ExpandLessOutlinedIcon onClick={() => setSlotType("")} />
                    ) : (
                      <ExpandMoreOutlinedIcon
                        onClick={() => setSlotType("afternoon")}
                      />
                    )}
                  </Grid>
                  {slotType === "afternoon" ? (
                    <Grid container>
                      {afterNoonSlots.length > 0 &&
                        afterNoonSlots?.map((val: any) => (
                          <Grid item xs={6} textAlign="center">
                            <Button
                              variant={
                                collectionSlot?.id === val.id
                                  ? "contained"
                                  : "outlined"
                              }
                              size="small"
                              color="primary"
                              onClick={() => setCollectionSlot(val)}
                            >
                              {val?.format_12_hrs?.start_time} -{" "}
                              {val?.format_12_hrs?.end_time}
                            </Button>
                          </Grid>
                        ))}
                    </Grid>
                  ) : (
                    ""
                  )}
                </Grid>

                <Grid container className="borderBox">
                  <Grid item xs={10}>
                    <img src={evening} /> Evening
                  </Grid>
                  <Grid item xs={2} textAlign="right">
                    {slotType === "evening" ? (
                      <ExpandLessOutlinedIcon onClick={() => setSlotType("")} />
                    ) : (
                      <ExpandMoreOutlinedIcon
                        onClick={() => setSlotType("evening")}
                      />
                    )}
                  </Grid>
                  {slotType === "evening" ? (
                    <Grid container>
                      {eveningSlots.length > 0 &&
                        eveningSlots?.map((val: any) => (
                          <Grid item xs={6} textAlign="center">
                            <Button
                              variant={
                                collectionSlot?.id === val.id
                                  ? "contained"
                                  : "outlined"
                              }
                              size="small"
                              color="primary"
                              onClick={() => setCollectionSlot(val)}
                            >
                              {val?.format_12_hrs?.start_time} -{" "}
                              {val?.format_12_hrs?.end_time}
                            </Button>
                          </Grid>
                        ))}
                    </Grid>
                  ) : (
                    ""
                  )}
                </Grid>
              </>
            )}
          </Grid>
        </Card>
      </Box>

      <Grid container>
        <Grid item xs={6}>
          <div style={{ margin: "0 15px 15px" }}>
            <StyledButtonLight
              onClick={() => setOpenSlotModal(false)}
              disabled={collectionDate === "" || collectionSlot === ""}
            >
              Back
            </StyledButtonLight>
          </div>
        </Grid>
        <Grid item xs={6}>
          <div style={{ margin: "0 15px 15px" }}>
            <StyledButton
              onClick={() => submitLead()}
              disabled={collectionDate === "" || collectionSlot === ""}
            >
              Submit
            </StyledButton>
          </div>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default CollectionSlotPage;
